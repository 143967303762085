<template>
  <div class="promoter-vendor-dashboard">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner" :class="{ beta: beta }">
        <div class="content-path">
          <div class="row mob" v-if="mobile">
            <div class="col full">
              <breadcrumbs :crumbs="breadcrumbs" />
            </div>
            <div class="col full">
              <diyobo-input
                v-if="currentEvent"
                type="dropdown"
                label="Jump To Another Guest List?"
                placeholder="Select Other Event"
                :options="eventList"
                v-model="currentEvent"
                :val="currentEvent"
                @input="changeEvent"
              />

              <!-- :error="formErrors.arrTime"
          v-model="formValues.arrTime"
          :val="formValues.arrTime"
          @keyup="validateAt('arrTime')" -->
            </div>
            <div class="col full" v-if="mobile">
              <diyobo-input
                type="dropdown"
                label=""
                placeholder=""
                :options="navDropdown"
                v-model="currentPage"
                :val="navDropdownSelected"
                @input="onPageSelect"
              />
            </div>
          </div>
          <div class="row" v-else>
            <div class="col half">
              <breadcrumbs :crumbs="breadcrumbs" />
            </div>
            <div class="col half">
              <diyobo-input
                v-if="currentEvent"
                type="dropdown"
                label="Jump To Another Guest List?"
                placeholder="Select Other Event"
                :options="eventList"
                v-model="currentEvent"
                :val="currentEvent"
                @input="changeEvent"
              />

              <!-- :error="formErrors.arrTime"
          v-model="formValues.arrTime"
          :val="formValues.arrTime"
          @keyup="validateAt('arrTime')" -->
            </div>
          </div>
          <div class="page-tabs">
            <tab-tote
              v-if="!mobile"
              :tabs="navTabs"
              :value="navTabSelected"
              v-on:click="onClickNavTab"
            />
          </div>
        </div>
        <router-view :url="url" @set-title="title = arguments[0]" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import TabTote from "@/components/TabTote.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import dateformat from "dateformat";

export default {
  name: "guestlist",
  components: {
    Breadcrumbs,
    TabTote,
    DiyoboInput
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "Guest Dashboard"
    };
  },
  data() {
    return {
      mobile: false,
      beta: true,
      eventList: [],
      currentEvent: "",
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Guest List", null]
      ],
      navTabs: [
        "Add To List",
        "View List",
        "Ranking",
        // "Embed",
        "List Settings"
      ],
      currentPage: "",
      currentList: "current-list",
      navDropdown: [
        {
          label: "Add To List",
          value: "add-to-list"
        },
        {
          label: "View List",
          value: "view-list"
        },
        {
          label: "Ranking",
          value: "rankings"
        },
        // {
        //   label: "Embed",
        //   value: "embed"
        // },
        {
          label: "List Settings",
          value: "settings"
        }
      ],

      title: ""
    };
  },
  computed: {
    navTabSelected() {
      const path = this.$route.path;

      if (path.endsWith("add-to-list")) {
        return 0;
      } else if (path.endsWith("view-list")) {
        return 1;
      } else if (path.endsWith("rankings")) {
        return 2;
      }
      // else if (path.endsWith("embed")) {
      //   return 3;
      // }
      else if (path.endsWith("settings")) {
        return 3;
      }
    },

    navDropdownSelected() {
      const path = this.$route.path;

      if (path.endsWith("add-to-list")) {
        return "add-to-list";
      } else if (path.endsWith("view-list")) {
        return "view-list";
      } else if (path.endsWith("rankings")) {
        return "rankings";
      }
      //  else if (path.endsWith("embed")) {
      //   return "embed";
      // }
      else if (path.endsWith("settings")) {
        return "settings";
      }
    }
  },
  methods: {
    onResize() {
      if (window.innerWidth < 801) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },
    onPageSelect(val) {
      const path = val;

      if (path.endsWith("add-to-list")) {
        this.$router.push(`/guestlist/${this.url}/add-to-list`);
      } else if (path.endsWith("view-list")) {
        this.$router.push(`/guestlist/${this.url}/view-list`);
      } else if (path.endsWith("rankings")) {
        this.$router.push(`/guestlist/${this.url}/rankings`);
      }
      //  else if (path.endsWith("embed")) {
      //   this.$router.push(`/guestlist/${this.url}/embed`);
      // }
      else if (path.endsWith("settings")) {
        this.$router.push(`/guestlist/${this.url}/list-settings`);
      }
    },
    onClickNavTab(index) {
      const path = this.$route.path;

      if (index === 0 && !path.endsWith("add-to-list")) {
        this.$router.push(`/guestlist/${this.url}/add-to-list`);
      } else if (index === 1 && !path.endsWith("view-list")) {
        this.$router.push(`/guestlist/${this.url}/view-list`);
      } else if (index === 2 && !path.endsWith("rankings")) {
        this.$router.push(`/guestlist/${this.url}/rankings`);
      }
      // else if (index === 3 && !path.endsWith("embed")) {
      //   this.$router.push(`/guestlist/${this.url}/embed`);
      // }
      else if (index === 3 && !path.endsWith("settings")) {
        this.$router.push(`/guestlist/${this.url}/list-settings`);
      }
    },
    changeEvent(url) {
      this.$router.push(`/guestlist/${url}/add-to-list`); // -> /user/eduardo
      // this.$router.push({ name: "guestlist-settings", params: { url } }); // -> /user/eduardo
    }
  },
  created() {
    const path = this.$route.path;
    const url = this.$route.params.url;
    this.$store.commit("setTitle", "Guest List");
    this.$store.commit(
      "setHelpVideoSrc",
      "https://youtube.com/embed/wgSiqHZnkZo"
    );
    // if (path.endsWith("manage")) {
    //   this.navTabSelected = 0;
    // } else if (path.endsWith("financials")) {
    //   this.navTabSelected = 1;
    // } else if (path.endsWith("rankings")) {
    //   this.navTabSelected = 2;
    // }
  },
  mounted() {
    if (window.innerWidth < 800) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    window.addEventListener("resize", this.onResize);

    this.$axios
      .post("/guestlist/get-guestlist-events", {
        event: this.url
      })
      .then(response => {
        const data = response.data;
        let newEventArr = [];
        data.events.forEach(event => {
          newEventArr.push({
            label:
              event.name +
              ` (${dateformat(
                Number(event.start.$date.$numberLong),
                "ddd, mmm dS, yyyy, h:MM TT"
              )})`,
            value: event.url
          });
        });

        this.eventList = newEventArr;

        let findIndex = this.eventList.findIndex(e => e.value === this.url);
        this.currentEvent = this.eventList[findIndex].value;
      });
  }
};
</script>

<style lang="less">
#Content {
  transition: height 0.5s;

  hr {
    max-width: 1100px;
    border: rgba(91, 91, 91, 0.5) solid 0.5px;
    margin-bottom: 25px;
  }

  .content-inner.beta {
    .row.mob {
      align-items: flex-end;
    }
    .content-path {
      padding-bottom: 0;

      hr {
        margin: 25px 0;
      }

      .buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        @media screen and (max-width: 555px) {
          justify-content: center;
        }
      }
      @media screen and (max-width: 800px) {
        .input-wrapper {
          margin-bottom: 0.5em;
        }
        .dropdown {
          padding: 8px 5px;
          font-size: 12px;
        }
        .option {
          font-size: 12px;
          padding: 8px 5px;
        }
      }
    }
    //end creation-path

    .page-tabs {
      padding: 20px 0;

      @media screen and (max-width: 800px) {
        padding: 0;
      }
    }

    h2 {
      text-align: center;
    }
    @media screen and (max-width: 730px) {
      padding-bottom: 160px;
    }
  }
  // end form-inner
}
</style>
